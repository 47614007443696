import { DEFAULT_LOCALE } from './../constants/constants';
import axios, { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import appConfig from '../app-config';
import { ApiResponse } from './types';
import { EContentTypes } from './enums';
import { checkSchemePresence } from '../utils/check-scheme-presence';
import { Capacitor } from '@capacitor/core';

const CLIENT_ID = 'Krujok.Online.Mobile';

export type TGrantType = 'refresh_token' | 'password' | 'cross_auth';

interface TokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
  silent_token: string;
  error?: string;
  error_description?: string;
}

const instance = axios.create({
  baseURL: appConfig.authUrl,
  headers: { 'Content-Type': EContentTypes.ApplicationXWWWFormUrlencoded },
  validateStatus: (_) => true,
});

const storageReader = {
  get userId(): string | null {
    return localStorage.getItem('userId');
  },
  get access_token(): string {
    return localStorage.getItem('access_token') ?? '';
  },
  get refresh_token(): string {
    return localStorage.getItem('refresh_token') ?? '';
  },
};

/**
 * Токен доступа
 */
function access_token() {
  return localStorage.getItem('access_token') ?? '';
}

/**
 * Токен обновления
 */
function refresh_token() {
  return localStorage.getItem('refresh_token') ?? '';
}

function getUserId() {
  return localStorage.getItem('userId') ?? undefined;
}

/**
 * Войти в систему
 * @param username - пользователь
 * @param password - пароль
 * @param i18n - ресуры
 */
function login({
  username,
  password,
  grantType = 'password',
  provider,
  access_token,
  verifier,
}: {
  username?: string;
  access_token?: string;
  password?: string;
  grantType?: TGrantType;
  provider?: string;
  verifier?: string;
}): Promise<ApiResponse> {
  const data = new FormData();
  data.append('client_id', CLIENT_ID);
  data.append('grant_type', grantType);
  access_token && data.append('token', access_token);
  username && data.append('username', username);
  password && data.append('password', password);
  provider && data.append('provider', provider);
  verifier && data.append('verifier', verifier);

  return token(data);
}

const logout = () => {
  localStorage.removeItem('userId');
  localStorage.removeItem('userName');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

function refresh(): Promise<ApiResponse> {
  const data = new FormData();

  data.append('client_id', CLIENT_ID);
  data.append('grant_type', 'refresh_token');
  data.append('refresh_token', refresh_token());

  return token(data);
}

async function token(data: FormData): Promise<ApiResponse> {
  const isNative = Capacitor.isNativePlatform();

  const scheme =
    isNative || process.env.NODE_ENV !== 'production'
      ? appConfig.scheme
      : localStorage.getItem('scheme');

  if (!checkSchemePresence()) {
    return {
      errorCode: 400,
      errorMsg: 'Scheme is missing',
    };
  }

  try {
    const response = await instance.post<TokenResponse>('token', data, {
      headers: {
        'x-language': localStorage.getItem('locale') || DEFAULT_LOCALE,
        'x-client-id': scheme,
        'x-client-type': isNative ? 'ios' : 'web',
      },
    });

    if (response.status === 200) {
      set(response.data.access_token, response.data.refresh_token);
      return {};
    }

    return {
      errorCode: response.status,
      errorMsg: response.data.error_description,
      silent_token: response.data.silent_token,
    };
  } catch (e) {
    return {
      errorCode: 500,
      errorMsg: 'Ошибка в запросе',
    };
  }
}

function set(access_token?: string, refresh_token?: string) {
  if (access_token) {
    localStorage.setItem('access_token', access_token);
  } else {
    localStorage.removeItem('access_token');
  }

  if (refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
  } else {
    localStorage.removeItem('refresh_token');
  }
}

interface IClaims {
  email?: string;
  name: string;
  role: string;
  sub: string;
}

function getClaims() {
  const token = storageReader.access_token;
  if (token) {
    return jwtDecode<IClaims>(token);
  }
}

const auth = {
  login,
  logout,
  refresh,
  getClaims,
  set,
  get access_token(): string {
    return access_token();
  },
  get refresh_token(): string {
    return refresh_token();
  },
  get authorized(): boolean {
    return !!this.access_token;
  },
  get userId(): string | undefined {
    return getUserId();
  },
};

export default auth;
