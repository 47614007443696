import { getProtocol } from '@utils/get-protocol';

export interface IAppConfig {
  readonly domain: string;
  readonly apiUrl: string;
  readonly apiServiceUrl: string;
  readonly authUrl: string;
  readonly isDebugMode: boolean;
  readonly scheme?: string;
  readonly theme?: string;
  readonly eljurDevId?: string;
  readonly eljurBaseUrl?: string;
  readonly vkAppId?: string;
  readonly welcomeEventId: number;
  readonly carrotquest: { readonly appKey?: string; readonly userAuthKey?: string };
  readonly googlePlayAppUrl?: string;
  readonly yMetrika?: string;
}

const get = (key: string) => process.env[key] ?? '';

function determineApiConfig() {
  const domain = get('REACT_APP_DOMAIN') || window.location.origin;

  const defaultConfig = {
    domain,
    apiUrl: domain + '/api',
    authUrl: domain + '/connect',
  };

  const iframeConfig = {
    domain: get('REACT_APP_DOMAIN_IN_IFRAME'),
    apiUrl: get('REACT_APP_DOMAIN_IN_IFRAME') + '/api',
    authUrl: get('REACT_APP_DOMAIN_IN_IFRAME') + '/connect',
  };

  const currentProtocol = getProtocol(window.location.href);
  const frameDomain = get('REACT_APP_DOMAIN_IN_IFRAME_FROM');

  if (Boolean(frameDomain) && document.referrer.includes(frameDomain)) {
    return {
      domain: `${currentProtocol}://${iframeConfig.domain}`,
      apiUrl: `${currentProtocol}://${iframeConfig.apiUrl}`,
      authUrl: `${currentProtocol}://${iframeConfig.authUrl}`,
    };
  }

  return defaultConfig;
}

const appConfig: IAppConfig = {
  apiServiceUrl: get('REACT_APP_SERVICE_API_URL'),
  domain: determineApiConfig().domain,
  apiUrl: determineApiConfig().apiUrl,
  authUrl: determineApiConfig().authUrl,
  isDebugMode: get('REACT_APP_MODE') === 'debug',
  scheme: get('REACT_APP_SCHEME'),
  theme: get('REACT_APP_THEME'),
  carrotquest: {
    appKey: get('REACT_APP_CARROTQUEST_API_KEY'),
  },
  vkAppId: get('REACT_APP_VK_ID'),
  welcomeEventId: Number(get('REACT_APP_WELCOME_EVENT_ID')),
  eljurDevId: get('REACT_APP_ELJUR_DEV_ID'),
  eljurBaseUrl: get('REACT_APP_ELJUR_BASE_URL'),
  googlePlayAppUrl: get('REACT_APP_GOOGLE_PLAY_APP_URL'),
  yMetrika: get('REACT_APP_YMETRIKA'),
};

export default appConfig;
