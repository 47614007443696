import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import appContext from '../app-context';
import auth from './auth';
import { EContentTypes } from './enums';
import { checkSchemePresence } from '@utils/check-scheme-presence';
import { applyHttpHeadersConfig } from '@utils/apply-http-headers-config';
import { normalizeObject } from '@utils/normalize-object';
import { isOrganizationSchemeRequest } from '@utils/is-organization-scheme-request';
import appConfig from '../app-config';
import { DEFAULT_LOCALE } from '../constants/constants';
import { Capacitor } from '@capacitor/core';

export interface IHttpRequestConfig extends AxiosRequestConfig {}
export interface IHttpResponse<T = any> extends AxiosResponse<T> {}

export default interface HttpClient {
  get: <T = any, R = IHttpResponse<T>>(url: string, config?: IHttpRequestConfig) => Promise<R>;
  post: <T = any, R = IHttpResponse<T>>(
    url: string,
    data?: any,
    config?: IHttpRequestConfig,
  ) => Promise<R>;
  put: <T = any, R = IHttpResponse<T>>(
    url: string,
    data?: any,
    config?: IHttpRequestConfig,
  ) => Promise<R>;
  delete: <T = any, R = IHttpResponse<T>>(url: string, config?: IHttpRequestConfig) => Promise<R>;
  patch: <T = any, R = IHttpResponse<T>>(
    url: string,
    data?: any,
    config?: IHttpRequestConfig,
  ) => Promise<R>;
}

export function createHttpClient(baseURL: string): HttpClient {
  (window as any).axios2 = axios;
  const isNative = Capacitor.isNativePlatform();

  const scheme =
    isNative || process.env.NODE_ENV !== 'production'
      ? appConfig.scheme
      : localStorage.getItem('scheme');

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': EContentTypes.ApplicationJSON,
      'x-os-name': appContext.os.name ?? '',
      'x-os-version': appContext.os.version ?? '',
      'x-cpu-architecture': appContext.cpu.architecture ?? '',
      'x-client-id': scheme,
      'x-client-type': isNative ? 'ios' : 'web',
      'x-client-name': appContext.client.name ?? '',
      'x-client-version': appContext.client.version ?? '',
      'x-device-id': appContext.device.id ?? '',
      'x-device-type': appContext.device.type ?? '',
      'x-device-model': appContext.device.model ?? '',
      'x-device-manufacturer': appContext.device.manufacturer ?? '',
      'x-language': localStorage.getItem('locale') || DEFAULT_LOCALE,
    },
    paramsSerializer: (params) => qs.stringify(normalizeObject(params)),
    validateStatus: (_) => true,
    withCredentials: true,
  });

  const execute = async <T = any, R = IHttpResponse<T>>(
    send: () => Promise<R>,
    method: string,
    url: string,
  ) => {
    // Если это не GET запрос на '/v1/organization/scheme' и схемы нет,
    // выбросить ошибку
    if (!isOrganizationSchemeRequest(method, url) && !checkSchemePresence()) {
      throw new Error('Scheme is missing');
    }

    const response: any = await send();
    if (response.status !== 401) {
      return response;
    }

    const refreshResponse = await auth.refresh();
    if (refreshResponse.errorCode) {
      auth.logout();
      return response;
    }

    return await send();
  };

  return {
    get: <T = any, R = IHttpResponse<T>>(url: string, config?: IHttpRequestConfig) =>
      execute<T, R>(() => axiosInstance.get<T, R>(url, applyHttpHeadersConfig(config)), 'get', url),

    post: <T = any, R = IHttpResponse<T>>(url: string, data?: any, config?: IHttpRequestConfig) =>
      execute<T, R>(
        () => axiosInstance.post<T, R>(url, data, applyHttpHeadersConfig(config)),
        'post',
        url,
      ),

    put: <T = any, R = IHttpResponse<T>>(url: string, data?: any, config?: IHttpRequestConfig) =>
      execute<T, R>(
        () => axiosInstance.put<T, R>(url, data, applyHttpHeadersConfig(config)),
        'put',
        url,
      ),

    delete: <T = any, R = IHttpResponse<T>>(url: string, config?: IHttpRequestConfig) =>
      execute<T, R>(
        () => axiosInstance.delete<T, R>(url, applyHttpHeadersConfig(config)),
        'delete',
        url,
      ),

    patch: <T = any, R = IHttpResponse<T>>(url: string, data?: any, config?: IHttpRequestConfig) =>
      execute<T, R>(
        () => axiosInstance.patch<T, R>(url, data, applyHttpHeadersConfig(config)),
        'patch',
        url,
      ),
  };
}
