import QuizQuestion from '../views/pages/quiz/quiz-question/quiz-question';

export enum EDifficulty {
  Easy = 0,
  Normal = 1,
  Hard = 2,
}

export enum EMediaType {
  Image = 'Image',
  Video = 'Video',
}

export enum EProjectModerationStatus {
  Created,
  Published,
  Blocked,
  Pending,
  InProcessing,
  ManualModeration,
  AutoModeration,
  Error,
  Deleted,
}

export enum EProjectSortingOrder {
  Default = 'Default',
  Recommended = 'Recommended',
  MostLiked = 'MostLiked',
  MostPopular = 'MostPopular',
}

export enum EEventType {
  Contest = 'Contest',
  Festival = 'Festival',
}

export enum EEventState {
  Draft = 'Draft',
  ComingSoon = 'ComingSoon',
  Active = 'Active',
  Summarizing = 'Summarizing',
  Completed = 'Completed',
  Archived = 'Archived',
}

export enum EBannerType {
  Main = 'Main',
  MainSquare = 'MainSquare',
  Prizes = 'Prizes',
}

export enum EImageFormat {
  Undefined,
  Jpeg,
  Png,
  Gif,
  Heic,
  Heif,
}

export enum ESkillSortingType {
  Round = 'Round',
  Popular = 'Popular',
  New = 'New',
}

export enum EChallengeSortingType {
  Round = 'Round',
  Popular = 'Popular',
  New = 'New',
  Easy = 'Easy',
  Hard = 'Hard',
  Fast = 'Fast',
  Patience = 'Patience',
}

export enum ENotificationType {
  Subscribe = 0,
  LikeProject = 1,
  Comment = 2,
  Experience = 3,
  Skill = 4,
  LikeComment = 5,
  Achievement = 6,
  Level = 7,
  ChallengeChanging = 8,
  Blocking = 9,
  AutoLock = 10,
  Unblocking = 11,
}

export enum ActionButtonState {
  Active = 'Active',
  Hidden = 'Hidden',
  Error = 'Error',
}

export enum EEventUserRatingType {
  Default = 'Default',
  General = 'General',
  Subdivision = 'Subdivision',
  Location = 'Location',
  Top100 = 'Top100',
}

export enum EChallengeState {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  IN_PROCESSING = 'InProcessing',
  IN_ARCHIVE = 'InArchive',
  PUBLISHED = 'Published',
  ERROR = 'Error',
}

export enum ESkillState {
  IN_ARCHIVE = 'InArchive',
  PUBLISHED = 'Published',
}

export enum EProjectCreateMode {
  CREATE = 'create',
  CREATE_WITHOUT_CHALLENGE = 'create-without-challenge',
  EDIT = 'edit',
}

export enum EChallengeSpeed {
  Fast = 0,
  Patience = 1,
}

export enum ECreateProjectErrorType {
  // without requst
  NO_MEDIA = 'no-media',
  NO_NAME = 'no_name',
  LONG_DESCRIPTION = 'long-description',
  TOO_BIG = 'too-big',
  // with requst
  LONG_VIDEO = 'long-video',
  SMALL_IMAGE = 'small-image',
  MAX_MEDIA = 'max-media',
  MAX_PROJECTS = 'max-projects',
  ASPECT_RATIO = 'aspect-ratio',
  SERVER_ERROR = 'server-error',
}

export enum EContentTypes {
  ApplicationJSON = 'application/json',
  ApplicationXWWWFormUrlencoded = 'application/x-www-form-urlencoded',
  MultipartFormData = 'multipart/form-data',
  VideoMP4 = 'video/mp4',
  VideoMov = 'video/quicktime',
  ImageJPEG = 'image/jpeg',
  ImagePNG = 'image/png',
}

export enum ECompanyNames {
  Round = 'round',
  Magnit = 'magnit',
  Pyatorochka = 'pyatorochka',
  Perekrestok = 'perekrestok',
  Korobka = 'korobka',
  Demo = 'demo',
  DemoEnglish = 'demoEnglish',
  Hmao = 'hmao',
  Nornickel = 'nornickel',
  Default = 'default',
  Sfr = 'sfr',
}

export enum EShowEventType {
  AllPeriod = 'AllPeriod',
  OnlyBeginDate = 'OnlyBeginDate',
}

export enum ELoadingStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum EAchievementItemType {
  Event = 'event',
  Interest = 'interest',
}

export enum ELanguage {
  RU = 'RU',
  EN = 'EN',
}

export enum EDocumentType {
  LicensingPolicy = 'licensingPolicy',
  PublicationRules = 'publicationRules',
  ExternalLibraries = 'externalLibraries',
}

export enum ESocial {
  Vkontakte = 'Vkontakte',
  Odnoklassniki = 'Odnoklassniki',
  Google = 'Google',
  Eljur = 'Eljur',
  Mail = 'Mail',
}

export enum EQuizType {
  SingleChoice = 'singleChoice',
  MultipleChoice = 'multipleChoice',
  TrueFalse = 'trueFalse',
  FillInTheBlank = 'fillInTheBlank',
}

export enum EQuizPage {
  QuizInfo = 'QuizInfo',
  QuizQuestion = 'QuizQuestion',
  QuizResult = 'QuizResult',
}

export enum ERegistrationReferralType {
  ImmediatelyAfterRegistration = 'ImmediatelyAfterRegistration',
  AfterApprovalFirstProject = 'AfterApprovalFirstProject',
}

export enum EQuizQuestionType {
  Open = 'Open',
}

export enum EStateComment {
  Deleted = 'Deleted',
  Published = 'Published',
}

export enum ERegistrationType {
  Open = 'Open',
}
